import React, { Component } from 'react';
import { HashRouter, BrowserRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
import './App.scss';
import axios from './cores/axios';
import DialogModal from './cores/DialogModal';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Login/Login'),
  loading
});

// Pages
const Pay = Loadable({
  loader: () => import('./views/Pay/Pay'),
  loading
});

// Pages
const ExportChart = Loadable({
  loader: () => import('./views/ExportChart/ExportChart'),
  loading
});

const PalmBoxList = Loadable({
  loader: () => import('./views/PalmBoxList/PalmBoxList'),
  loading
});

const PalmBox = Loadable({
  loader: () => import('./views/PalmBox/PalmBox'),
  loading
});

const PalmBoxC2B = Loadable({
  loader: () => import('./views/PalmBoxC2B/PalmBoxC2B'),
  loading
});

const PalmBoxC2BAll = Loadable({
  loader: () => import('./views/PalmBoxC2BAll/PalmBoxC2BAll'),
  loading
});

const Register = Loadable({
  loader: () => import('./views/Register/Register'),
  loading
});

const Orders = Loadable({
  loader: () => import('./views/Pages/Orders'),
  loading
});

const OrderEinvoice = Loadable({
  loader: () => import('./views/Pages/Orders/Einvoice'),
  loading
});

const OrderVerify = Loadable({
  loader: () => import('./views/Pages/Orders/Verify'),
  loading
});

const OrderUpdate = Loadable({
  loader: () => import('./views/Pages/Orders/Update'),
  loading
});

const OrderVerifyList = Loadable({
  loader: () => import('./views/Pages/Orders/VerifyList'),
  loading
});

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
});

const Thanks = Loadable({
  loader: () => import('./views/Pages/Thanks'),
  loading
});

const Step = Loadable({
  loader: () => import('./views/Pages/Step'),
  loading
});

const OrderDetects = Loadable({
  loader: () => import('./views/Pages/Orders/Detects'),
  loading
});

const StatusSettings = Loadable({
  loader: () => import('./views/Pages/StatusSettings'),
  loading
});

const StoreTimes = Loadable({
  loader: () => import('./views/Pages/Stores/Times'),
  loading
});

const StoreTimesInfo = Loadable({
  loader: () => import('./views/Pages/Stores/Times/Info'),
  loading
});

const StoreTimesSpecify = Loadable({
  loader: () => import('./views/Pages/Stores/Times/Specify'),
  loading
});

const StoreTimesAllow = Loadable({
  loader: () => import('./views/Pages/Stores/Times/Allow'),
  loading
});

const DisabledCalendar = Loadable({
  loader: () => import('./views/Pages/Stores/Times/DisabledCalendar'),
  loading
});

const StoreHolidays = Loadable({
  loader: () => import('./views/Pages/Stores/Times/Holidays'),
  loading
});

const RfidScheduler = Loadable({
  loader: () => import('./views/Pages/RfidScheduler'),
  loading
});

const ExternalScheduler = Loadable({
  loader: () => import('./views/Pages/ExternalScheduler'),
  loading
});

const ReservationCalendar = Loadable({
  loader: () => import('./views/Pages/ReservationCalendar'),
  loading
});

const Consignment = Loadable({
  loader: () => import('./views/Pages/Consignment'),
  loading
});

const GamesExcel = Loadable({
  loader: () => import('./views/GamesExcel'),
  loading
});

const CalendarsExcel = Loadable({
  loader: () => import('./views/CalendarsExcel'),
  loading
});

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      message: '',
      level: 'danger'
    };
  }

  handleCloseModal = () => {
    this.setState({
      open: false
    });
  }

  handleRelogin = () => {
    this.setState({
      open: false
    });
    window.location = "/login";
  }

  componentWillMount() {
    axios.interceptors.request.use((config) => {
        if (localStorage.JWT_TOKEN) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
          config.headers.Authorization = `Bearer ${localStorage.JWT_TOKEN}`;
        }
        return config;
      },(err) => {
        return Promise.reject(err);
      });


    axios.interceptors.response.use((response) => {
      return response;
    },(error) => {
      let close = this.handleCloseModal
      let msg = '';
      if (error.response != undefined) {
        switch(error.response.status) {
          case 401:
            msg = 'Token 失效, 請重新登入'
            close = this.handleRelogin
            break;
          case 405:
            msg = '異常錯誤'
            close = this.handleCloseModal
            break;
          case 500: case 403:
            if (error.response.data !== null) {
              Object.values(error.response.data.data).map((arr) => {
                arr.map((value) => {
                  msg += value + '\n';
                })
              })
              close = this.handleCloseModal
            } else {
              msg = '異常錯誤';
            }
            break;
          case 404:
            msg = '查無此頁面'
            close = this.handleCloseModal
            break;
          default:
            msg = 'Token 失效, 請重新登入'
            close = this.handleRelogin
            break;
        }
      }

      this.setState({
        open: true,
        message: msg,
        close: close
      });

      return Promise.reject(error);
    });
  }

  render() {
    return (
      <div>
        <DialogModal open={this.state.open} message={this.state.message} level={this.state.level} close={this.state.close}/>
        <BrowserRouter>
            <Switch>
              <Route exact path="/login" name="Login Page" component={Login} />
              <Route exact path="/register" name="Register Page" component={Register} />
              <Route exact path="/pay" name="Pay Page" component={Pay} />
              <Route exact path="/pay/:id" name="Pay Page" component={Pay} />
              <Route exact path="/palmbox-list" name="PalmBoxC2B Page" component={PalmBoxList} />
              <Route exact path="/palmbox-b2c" name="PalmBoxB2C Page" component={PalmBox} />
              <Route exact path="/palmbox-c2b" name="PalmBoxC2B Page" component={PalmBoxC2B} />
              <Route exact path="/palmbox-c2b-all" name="PalmBoxC2B Page" component={PalmBoxC2BAll} />
              <Route exact path="/404" name="Page 404" component={Page404} />
              <Route exact path="/500" name="Page 500" component={Page500} />
              <Route exact path="/thanks" name="Thanks" component={Thanks} />
              <Route exact path="/orders/verify" name="Order Verify Page" component={OrderVerifyList} />
              <Route exact path="/orders/detect" name="Order Verify Page" component={OrderDetects} />
              <Route exact path="/orders/:id" name="Order Page" component={Orders} />
              <Route exact path="/orders/:id/einvoice" name="Order Einvoice Page" component={OrderEinvoice} />
              <Route exact path="/orders/:id/verify" name="Order Verify Page" component={OrderVerify} />
              <Route exact path="/orders/:id/update" name="Order Update Page" component={OrderUpdate} />
              <Route exact path="/step" name="Step" component={Step} />
              <Route exact path="/export-chart" name="ExportChart" component={ExportChart} />
              <Route exact path="/status-settings" name="StatusSettings" component={StatusSettings} />
              <Route exact path="/stores/times" name="StoreTimes" component={StoreTimes} />
              <Route exact path="/stores/times/holidays" name="StoreHolidays" component={StoreHolidays} />
              <Route exact path="/stores/times/:id/info" name="StoreTimesInfo" component={StoreTimesInfo} />
              <Route exact path="/stores/times/:id/specify" name="StoreTimesSpecify" component={StoreTimesSpecify} />
              <Route exact path="/stores/times/:id/allow" name="StoreTimesAllow" component={StoreTimesAllow} />
              <Route exact path="/stores/times/disabled_calendar" name="DisabledCalendar" component={DisabledCalendar} />


              <Route exact path="/external-scheduler" name="RfidScheduler" component={ExternalScheduler} />
              <Route exact path="/rfid-scheduler" name="RfidScheduler" component={RfidScheduler} />
              <Route exact path="/reservation-calendar" name="ReservationCalendar" component={ReservationCalendar} />

              <Route exact path="/consignment" name="Consignment" component={Consignment} />
              <Route exact path="/games-excel" name="GamesExcel" component={GamesExcel} />
              <Route exact path="/calendars-excel" name="CalendarsExcel" component={CalendarsExcel} />

              <Route path="/" name="Home" component={DefaultLayout} />
            </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
