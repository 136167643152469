import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

const ModalString = {
  danger: '錯誤',
  info: '資訊',
  success: '成功',
  warning: '警告',
}

class DialogModal extends Component {

  render() {
    let footer;
    if (!this.props.checked) {
      footer = <ModalFooter>
        {!this.props.confirmHide ? <Button color={this.props.level} onClick={this.props.close}>確認</Button> : ''}
      </ModalFooter>;
    } else {
      footer = <ModalFooter>
        {!this.props.confirmHide ? <Button color="primary" onClick={this.props.confirm}>確認</Button> : ''}
        <Button color="secondary" onClick={this.props.close}>取消</Button>
      </ModalFooter>;
    }

    return (
      <Modal isOpen={this.props.open} toggle={this.props.close}
             className={`modal-${this.props.level} ` + this.props.className}>
        <ModalHeader toggle={this.toggleDanger}>{ModalString[this.props.level]}</ModalHeader>
        <ModalBody style={{whiteSpace: 'pre-line'}}>
            {this.props.message}
        </ModalBody>
        {footer}
      </Modal>
    );
  }
}

export default DialogModal;
